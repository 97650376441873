import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className='py-12 md:py-16'>
        <div className='max-w-6xl mx-auto px-4 sm:px-6'>
          {/* Top area: Blocks */}
          <div className='grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12'>
            {/* 1st block */}
            <div className='md:col-span-4 lg:col-span-5'>
              <div className='mb-2'>
                {/* Logo */}
                <Link to='/' className='inline-block' aria-label='Cruip'>
                  <img
                    className='w-25 h-5'
                    src={
                      require('../images/logos/totally-logo-indigo_on_trans.png')
                        .default
                    }
                    alt='TotallyApp'
                  />
                </Link>
              </div>
              <div className='text-gray-400' style={{ fontWeight: 'bold' }}>
                Business Software. Simplified.
              </div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div
              className='md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8'
              style={{ border: '0px solid' }}
            >
              {/* 2nd block */}
              <div
                className='text-sm'
                style={{
                  flexGrow: '1',
                  height: '1px',
                  border: '0px solid red',
                }}
              ></div>

              {/* 3rd block */}
              <div
                className='text-sm'
                style={{
                  flexGrow: '1',
                  height: '1px',
                  border: '0px solid red',
                }}
              ></div>

              {/* 4th block */}
              <div
                className='text-sm'
                style={{ flexGrow: '1', border: '0px solid red' }}
              >
                <ul>
                  <li className='mb-1'>Totally Business Software Pty Ltd</li>
                  <li className='mb-1'>ABN 64649082299</li>
                  <li className='mb-1'>
                    Level 33, 152 St Georges Terrace, Perth WA
                  </li>
                  <li className='mb-1'>Phone + 61 408 702 047</li>
                  <li className='mb-1'>allan@totallyapp.com.au</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className='md:flex md:items-center md:justify-between'>
            {/* Copyrights note */}
            <div className='text-gray-400 text-sm mr-4'>
              &copy; 2024 Totally Business Software Pty Ltd
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
